'use strict';

var _uikit = require('uikit');

var _uikit2 = _interopRequireDefault(_uikit);

var _uikitIcons = require('uikit/dist/js/uikit-icons');

var _uikitIcons2 = _interopRequireDefault(_uikitIcons);

var _vue = require('vue');

var _vue2 = _interopRequireDefault(_vue);

var _lodash = require('lodash');

var _lodash2 = _interopRequireDefault(_lodash);

var _vueLodash = require('vue-lodash');

var _vueLodash2 = _interopRequireDefault(_vueLodash);

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* Index.js start */
require('../scss/main.scss');

_uikit2.default.use(_uikitIcons2.default);
window.UIkit = _uikit2.default;

_vue2.default.use(_vueLodash2.default, _lodash2.default);

//Axios

window.Axios = require('axios');

if (process.env.NODE_ENV === 'production') {
    _vue2.default.config.devtools = false;
    _vue2.default.config.debug = false;
    _vue2.default.config.silent = true;
}

/* IMPORT COMPOENNTS */
// import { importComponents} from './components-importer'
// importComponents()

// import { importMapComponents} from './components-importer-map'
// importComponentsMap()

// import { importGFormComponents} from './components-importer-gfrom'
// importComponentsGForm()